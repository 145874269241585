import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { initializeApp } from "firebase/app";
import "./App.css";

import { Home } from "./pages/Home";
import Works from "./pages/Works";
import { Rabbit } from "./pages/2023-01_rabbit";
import { Kawasemi } from "./pages/2022-08_kawasemi";
import { AssortmentOfJune } from "./pages/2022-06_assortment-of-june";
import { SpecimenOfAutumn } from "./pages/2022-11_specimen-of-authumn";
import { Fish } from "./pages/2023-03_school-of-fish";
import { Egg } from "./pages/2023-05_an-egg/";
import RainyDaysAnd from "./pages/2023-05_rainy-days-and";
import PolarBear from "./pages/2023-08_polar-bear";
import SunFlower from "./pages/2024-04_sun-flower";
import EndOfSummer from "./pages/2024-08_end-of-summer";

function App() {
  var firebaseConfig = {
    apiKey: "AIzaSyCxcxCzS2_ZNMMMcP3cA7o-Mu-RUKm6GuQ",
    authDomain: "porch-website.firebaseapp.com",
    databaseURL: "https://porch-website-default-rtdb.firebaseio.com",
    projectId: "porch-website",
    storageBucket: "porch-website.appspot.com",
    messagingSenderId: "814249724574",
    appId: "1:814249724574:web:ae4bc551a9e399855dc98f",
    measurementId: "G-LTM6FJFH2L",
  };

  initializeApp(firebaseConfig);

  return (
    <div className="container-fluid" style={{ maxWidth: 1000 }}>
      <BrowserRouter>
        <Routes>
          <Route path={`/`} element={<Home />} />
          <Route path={`/works/`} element={<Works />} />
          <Route path={`/l/`} element={<EndOfSummer />} />
          <Route path={`/s/`} element={<SunFlower />} />
          <Route path={`/2024-08_end-of-summer/`} element={<EndOfSummer />} />
          <Route path={`/2024-04_sun-flower/`} element={<SunFlower />} />
          <Route path={`/2023-08_polar-bear/`} element={<PolarBear />} />
          <Route path={`/2023-05_rainy-days-and/`} element={<RainyDaysAnd />} />
          <Route path={`/2023-05_an-egg/`} element={<Egg />} />
          <Route path={`/2023-03_school-of-fish/`} element={<Fish />} />
          <Route path={`/2023-01_rabbit/`} element={<Rabbit />} />
          <Route path={`/2022-08_kawasemi/`} element={<Kawasemi />} />
          <Route
            path={`/2022-06_assortment_of_june/`}
            element={<AssortmentOfJune />}
          />
          <Route
            path={`/2022-11_specimen-of-authumn/`}
            element={<SpecimenOfAutumn />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
